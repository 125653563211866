import React, { Component } from 'react'
import imageCompression from 'browser-image-compression'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import services from '@services'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import { BiTrash } from 'react-icons/bi'
import { IoInformationCircleOutline } from 'react-icons/io5'
import Dropdown from '@components/common/form/Dropdown'
import DividerLine from '@components/common/DividerLine'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import { TechHelpForm } from '@components/common'
import Input from '@components/common/form/Input'
import TextArea from '@components/common/form/TextArea'
import {
  IconButton,
  Label,
  OrderFormSectionTitle,
} from '@res/styledComponents/index'
import {
  colors,
  DefaultChefProcessingFee,
  MaxImageSizeKB,
} from '../../../../constants'
import {
  DateTextInput,
  Checkbox,
  CurrencyInput,
  NumberInput,
  QuantityInput,
  RadioButton,
  TextInput,
  AutocompleteInput,
} from '@components/common/form'
import { AddressInput } from '@containers/common/form'
import { AuthorizedInteractable } from '@containers/common/auth'
import { TooltipModal } from '../../common/modal'

const ChefPriorityTypes = ['None', 'New', 'Anchor']
const ChefTypes = [
  { value: 'Chef', label: 'Chef' },
  { value: '', label: 'Restaurant' },
]
export const CommunicationPreferences = [
  { value: 'email', label: 'Email' },
  { value: 'sms', label: 'SMS' },
  { value: 'both', label: 'Both' },
  { value: 'manual', label: 'Manual' },
]

const editChefModalInitialState = {
  allStatuses: [],
  cateringEarliestPickupTime: null,
  chefPriorityType: 'None',
  chefType: '',
  customMenuImageUrl: '',
  customMenuImageFile: undefined,
  editPayoutMethod: false,
  groupOrderEarliestPickupTime: null,
  isBusiness: false,
  logoUrl: '',
  logoFile: undefined,
  maxGroupOrderItems: 120,
  onSiteFee: '0.0',
  processingFee: DefaultChefProcessingFee,
  communicationPreferences: 'both',
  status: 'inactive',
}

class EditChefModal extends Component {
  state = { ...editChefModalInitialState }

  componentWillMount() {
    const { clearErrors } = this.props
    clearErrors()

    if (!this.props.settings) {
      ;(async () => {
        const settings = await this.props.loadChefsSettings()
        this.setState({ ...settings, ...this.props.chef })
      })()
    } else {
      const { chef, settings } = this.props
      this.setState({ ...editChefModalInitialState, ...settings, ...chef })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps.chef })
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key }, this.delayedUpdate)
  }

  onDelete = (e) => {
    e.stopPropagation()
    e.preventDefault()

    // const { firstName, id } = this.state
    // this.props.deleteChef( id, firstName )
    this.props.displayFailureMessage(
      'Please ask a member of the tech team to either delete this chef or remove from this market.',
    )
  }

  onHide = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    this.props.close()
  }

  onSubmit = (e) => {
    const { delayedUpdateChef, saveChef, displayFailureMessage } = this.props
    e.stopPropagation()
    e.preventDefault()
    delayedUpdateChef(this.state)

    const {
      email,
      line1,
      city,
      state,
      zip,
      maxGroupOrderItems,
      processingFee,
      processingFeeReason,
    } = this.state
    switch (true) {
      case !line1:
        return displayFailureMessage('Address is missing line1')
      case !city:
        return displayFailureMessage('Address is missing city')
      case !state:
        return displayFailureMessage('Address is missing state')
      case !zip:
        return displayFailureMessage('Address is missing zip')
      case !email:
        return displayFailureMessage('Email is missing')
      case isNaN(maxGroupOrderItems):
        return displayFailureMessage(
          'Must have a maximum number of group order items set',
        )
    }

    if (
      processingFee !== DefaultChefProcessingFee &&
      (!processingFeeReason || processingFeeReason.trim().length < 3)
    ) {
      return displayFailureMessage(
        `If processing fee is different from the standard ${DefaultChefProcessingFee} processing fee rate there must be a sufficiently long reason added (>= 3 characters)`,
      )
    }

    saveChef(this.state)
  }

  onSaveLogo = async () => {
    const { logoFile } = this.state
    let validImage = false
    let logoUrl = ''
    logoUrl = await this.props.uploadChefLogoImage(logoFile)
    if (logoUrl) {
      validImage = await this.props.checkPdfImage(logoUrl)

      const newImageFile = await imageCompression(logoFile, {
        fileType: 'image/jpeg',
      })

      logoUrl = await this.props.uploadChefLogoImage(newImageFile)
      if (logoUrl) {
        validImage = true
      }

      if (logoUrl && validImage) {
        this.setState({ logoFile: undefined, logoUrl })
      }
    }
  }

  onSaveCustomMenuImage = async () => {
    const { customMenuImageFile } = this.state
    let validImage = false
    let customMenuImageUrl = ''
    customMenuImageUrl =
      await this.props.uploadChefCustomMenuImage(customMenuImageFile)
    if (customMenuImageUrl) {
      validImage = await this.props.checkPdfImage(customMenuImageUrl)

      const newImageFile = await imageCompression(customMenuImageFile, {
        fileType: 'image/jpeg',
      })

      customMenuImageUrl =
        await this.props.uploadChefCustomMenuImage(newImageFile)
      if (customMenuImageUrl) {
        validImage = true
      }

      if (customMenuImageUrl && validImage) {
        this.setState({ customMenuImageFile: undefined, customMenuImageUrl })
      }
    }
  }

  updateChef = (key) => (e) => {
    const { value } = e.target
    const valueWithoutUnicode =
      // eslint-disable-next-line no-control-regex
      typeof value === 'string' ? value.replace(/[^\x00-\x7F]/g, '') : value
    const { delayedUpdateChef } = this.props
    this.setState({ [key]: valueWithoutUnicode }, () =>
      delayedUpdateChef(this.state),
    )
  }

  updateChefValue = (key) => (value) => {
    const { delayedUpdateChef, displayFailureMessage } = this.props

    if (key === 'image') {
      const { size } = value
      if (size / 1024 > MaxImageSizeKB) {
        displayFailureMessage(
          `Image size must be less than ${MaxImageSizeKB}KB`,
        )

        return
      }
    }

    this.setState({ [key]: value }, () => delayedUpdateChef(this.state))
  }

  updatePickupAddress = (pickupAddress) => {
    const { delayedUpdateChef } = this.props
    const {
      id: pickupAddressId,
      line1,
      line2,
      city,
      state,
      zip,
      allowTolls,
      buildingInstructions,
    } = pickupAddress

    this.setState(
      {
        pickupAddressId,
        line1,
        line2,
        city,
        state,
        zip,
        allowTolls,
        buildingInstructions,
        updatedPickupAddress: true,
      },
      () => delayedUpdateChef(this.state),
    )
  }

  updateChefRating = (value) => {
    const floatValue = parseFloat(value).toFixed(2)
    if (!Number.isNaN(floatValue)) {
      if (floatValue > 5.0) {
        value = '5.0'
      }
      if (floatValue < 0.0) {
        value = '0.0'
      }
      this.updateChefValue('rating')(value)
    } else {
      this.updateChefValue('rating')('')
    }
  }

  onKeyDownNewLine = (key) => (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      const { selectionStart } = event.target
      let { value } = event.target
      value =
        value.slice(0, selectionStart) + '\n' + value.slice(selectionStart)
      this.updateChefValue(key)(value)
      event.preventDefault()
    }
  }

  onClickEditPayout = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ editPayoutMethod: true })
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    const { delayedUpdateChef } = this.props
    this.setState({ [key]: checked }, () => delayedUpdateChef(this.state))
  }

  onSearch = async (e) => {
    const { loadChefs } = this.props
    const { search } = e
    const chefs = await loadChefs(search)
    if (chefs) {
      return chefs
    }
  }

  onSelectExistingChef = async (e) => {
    const { selectChef } = this.props
    const chef = await selectChef(e.id)
    if (chef) {
      this.setState({ ...chef })
    }
  }

  onChooseLogo = (e) => {
    const logoFile = e.target.files[0]
    this.setState({ logoFile })
  }

  onChooseCustomMenuImage = (e) => {
    const customMenuImageFile = e.target.files[0]
    this.setState({ customMenuImageFile })
  }

  renderPayoutMethod() {
    const {
      accountNumberLast4,
      businessName,
      doingBusinessAs,
      etinOrFeinLast4,
      isBusiness,
      routingNumber,
      ssnLast4,
      payoutMethodStreetAddress,
      payoutMethodCity,
      payoutMethodState,
      payoutMethodZip,
    } = this.state
    const { errors } = this.props

    return (
      <div className="form-field-container">
        <h2> Payout Method </h2>
        <div>
          {isBusiness ? (
            <div>
              <div className="form-field-container__columns">
                <p>Business Name</p>
                <TextInput
                  placeholder="Google"
                  value={businessName}
                  error={
                    errors.payoutMethod && errors.payoutMethod.businessName
                  }
                  onInput={this.updateChef('businessName')}
                />
              </div>

              <div className="form-field-container__columns">
                <p>Doing Business as Name</p>
                <TextInput
                  placeholder="Google"
                  value={doingBusinessAs}
                  error={
                    errors.payoutMethod && errors.payoutMethod.doingBusinessAs
                  }
                  onInput={this.updateChef('doingBusinessAs')}
                />
              </div>

              <div className="form-field-container__columns">
                <p>ETIN or FEIN</p>
                <TextInput
                  placeholder="12345678"
                  value={etinOrFeinLast4}
                  error={
                    errors.payoutMethod && errors.payoutMethod.etinOrFeinLast4
                  }
                  onInput={this.updateChef('etinOrFeinLast4')}
                />
              </div>
            </div>
          ) : (
            <div className="form-field-container__columns">
              <p>SSN</p>
              <TextInput
                placeholder="1234"
                value={ssnLast4}
                error={errors.payoutMethod && errors.payoutMethod.ssnLast4}
                onInput={this.updateChef('ssnLast4')}
              />
            </div>
          )}
          <div className="form-field-container__columns">
            <p>Street Address</p>
            <TextInput
              placeholder="Street Address"
              value={payoutMethodStreetAddress}
              error={
                errors.payoutMethod &&
                errors.payoutMethod.payoutMethodStreetAddress
              }
              onInput={this.updateChef('payoutMethodStreetAddress')}
            />
          </div>

          <div className="form-field-container__columns">
            <p>City</p>
            <TextInput
              placeholder="City"
              value={payoutMethodCity}
              error={
                errors.payoutMethod && errors.payoutMethod.payoutMethodCity
              }
              onInput={this.updateChef('payoutMethodCity')}
            />
          </div>

          <div className="form-field-container__columns">
            <p>State</p>
            <TextInput
              placeholder="State"
              value={payoutMethodState}
              error={
                errors.payoutMethod && errors.payoutMethod.payoutMethodState
              }
              onInput={this.updateChef('payoutMethodState')}
            />
          </div>

          <div className="form-field-container__columns">
            <p>Zip</p>
            <TextInput
              placeholder="Zip"
              value={payoutMethodZip}
              error={errors.payoutMethod && errors.payoutMethod.payoutMethodZip}
              onInput={this.updateChef('payoutMethodZip')}
            />
          </div>

          <div className="form-field-container__columns">
            <p>Bank Routing Number</p>
            <NumberInput
              placeholder="123456789"
              value={routingNumber}
              error={errors.payoutMethod && errors.payoutMethod.routingNumber}
              onInput={this.updateChefValue('routingNumber')}
            />
          </div>

          <div className="form-field-container__columns">
            <p>Bank Account Number</p>
            <NumberInput
              placeholder="1234567890"
              value={accountNumberLast4}
              error={
                errors.payoutMethod && errors.payoutMethod.accountNumberLast4
              }
              onInput={this.updateChefValue('accountNumberLast4')}
            />
          </div>
        </div>
      </div>
    )
  }

  renderPayToDetails = () => {
    const { UIService } = services
    UIService.Alert.showPopup({
      message: "This is the Chef/Restaurant's legal name for Chef Payouts",
    })
  }

  pEarliestPickupTime = (pickupTime) => {
    const { locale } = this.props
    if (!pickupTime) {
      return null
    }

    return Moment()
      .tz(locale)
      .set({
        h: pickupTime / 100,
        m: pickupTime % 100,
        s: 0,
      })
  }

  onChangeEarliestPickupTime = (key) => (value) => {
    const { locale, delayedUpdateChef } = this.props

    let pickupTime = null
    if (value && Moment(value).isValid()) {
      const m = Moment(value).tz(locale)
      pickupTime = m.hour() * 100 + m.minute()
    }

    this.setState({ [key]: pickupTime }, () => delayedUpdateChef(this.state))
  }

  renderProcessingFeeDetails = () => {
    const { UIService } = services
    UIService.Alert.showPopup({
      message: `The processing fee is the rate at which chefs are charged every week in order to offset expenses for payment processing and collection services. The default rate is ${DefaultChefProcessingFee}.`,
    })
  }

  renderProcessingFeeReasonDetails = () => {
    const { UIService } = services
    UIService.Alert.showPopup({
      message: `A processing fee reason must be provided if the fee is different from the ${DefaultChefProcessingFee} default processing fee.`,
    })
  }

  onChangeCommunicationPreference = (e) => {
    const { value } = e.target
    this.setState({ communicationPreferences: value })
  }

  render() {
    const { locale, delayedUpdateChef } = this.props
    const {
      allStatuses,
      chefPriorityType,
      email,
      firstName,
      id,
      lastName,
      phoneNumber,
      payToName,
      accomplishments,
      accountName,
      advanceHrsNotice,
      description,
      headquarters,
      hideFromMapView,
      hometown,
      isBusiness,
      isCloudKitchensPartner,
      maxBuffet,
      maxIndividual,
      maxGroupOrderItems,
      minCateringPayout,
      onSiteFee,
      pickupAddressId,
      line1,
      line2,
      city,
      state,
      zip,
      allowTolls,
      buildingInstructions,
      status,
      vendorOnly,
      yearsCooking,
      chefType,
      customPath,
      isVcx,
      existingChef,
      logoUrl,
      logoFile,
      customMenuImageUrl,
      customMenuImageFile,
      videoLink,
      showVideoPopup,
      groupOrderEarliestPickupTime,
      cateringEarliestPickupTime,
      processingFee,
      processingFeeReason,
      communicationPreferences,
    } = this.state

    const { errors, isLoading } = this.props
    const title = id ? 'Edit Chef' : 'New Chef'
    const pickupAddress = {
      id: pickupAddressId,
      line1,
      line2,
      city,
      state,
      zip,
      allowTolls,
      buildingInstructions,
    }
    const accountNameError =
      errors.chefProfile && errors.chefProfile.accountName
    const editRoles = ['master admin', 'chef lead', 'sales lead']
    if (vendorOnly) {
      editRoles.push('sales rep')
    }

    const hqStr = headquarters && headquarters.map((hq) => hq.name).join(', ')

    return (
      <Modal
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        {headquarters && headquarters.length > 1 && (
          <div>
            <label className="font-bold">
              Adjusting information within the{' '}
              <span className="text-red-600">
                Chef Details/Profile/Capabilities
              </span>{' '}
              will be reflected across all headquarters this chef is associated
              with: <span className="text-red-600">{hqStr}</span>
            </label>
            <YSpacing height="10px" />
          </div>
        )}
        <OrderFormSectionTitle>Chef Details</OrderFormSectionTitle>
        <YSpacing height="20px" />
        {!id && (
          <div>
            <AutocompleteInput
              label="Existing Chef"
              displayAttribute="name"
              width="31%"
              loaderFunction={this.onSearch}
              value={existingChef && existingChef.name}
              onSelect={this.onSelectExistingChef}
            />
            <YSpacing height="10px" />
          </div>
        )}
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <Input
            width="31%"
            label="First Name"
            value={firstName}
            error={errors.firstName}
            onChange={this.updateChef('firstName')}
          />
          <Input
            width="31%"
            label="Last Name"
            value={lastName}
            error={errors.lastName}
            onChange={this.updateChef('lastName')}
          />
          <Input
            width="31%"
            label="Email"
            value={email}
            error={errors.email}
            onChange={this.updateChef('email')}
          />
          <Input
            width="31%"
            label="Phone Number"
            value={phoneNumber}
            error={errors.phoneNumber}
            onChange={this.updateChef('phoneNumber')}
          />
          <Dropdown
            label="Status"
            marginBottom="0"
            width="31%"
            onChange={this.updateChef('status')}
          >
            {allStatuses.map((o) => (
              <option key={`chefStatus${o}`} value={o} selected={status === o}>
                {o}
              </option>
            ))}
          </Dropdown>
          <Dropdown
            label="Chef Type"
            width="31%"
            onChange={this.updateChef('chefType')}
          >
            {ChefTypes.map(({ value, label }) => (
              <option
                key={`chefType${value}`}
                value={value}
                selected={chefType === value}
              >
                {label}
              </option>
            ))}
          </Dropdown>
        </FlexContainer>
        <FlexContainer>
          <Input
            width="31%"
            label="Pay To"
            value={payToName}
            errors={errors.payToName}
            onChange={this.updateChef('payToName')}
          />
          <IoInformationCircleOutline
            fontSize="15px"
            onMouseOver={() => this.renderPayToDetails()}
          />
        </FlexContainer>
        <Checkbox
          label="Is Business"
          value="isBusiness"
          checked={isBusiness}
          onChange={this.onChangeCheckbox}
        />
        <YSpacing height="5px" />
        <Checkbox
          label="Is VCX"
          value="isVcx"
          checked={isVcx}
          onChange={this.onChangeCheckbox}
        />
        <YSpacing height="5px" />
        <Checkbox
          label="Vendor Only (will not have concepts in the marketplace)"
          value="vendorOnly"
          checked={vendorOnly}
          onChange={this.onChangeCheckbox}
        />
        <YSpacing height="5px" />
        <Checkbox
          label="Hide Chef on Client/Chef Map"
          value="hideFromMapView"
          checked={hideFromMapView}
          onChange={this.onChangeCheckbox}
        />
        <YSpacing height="5px" />
        <Checkbox
          label="Cloud Kitchens Partner"
          value="isCloudKitchensPartner"
          checked={isCloudKitchensPartner}
          onChange={this.onChangeCheckbox}
        />
        <YSpacing height="20px" />
        {!vendorOnly && (
          <div>
            <Label>Chef Priority Type</Label>
            <YSpacing height="5px" />
            {ChefPriorityTypes.map((t) => (
              <div key={t}>
                <RadioButton
                  name="chefPriorityType"
                  value={t}
                  checked={t === chefPriorityType}
                  onChange={this.onChangeRadioButton}
                />
                <YSpacing height="5px" />
              </div>
            ))}
          </div>
        )}
        <DividerLine margin="20px 0" />
        {!vendorOnly && (
          <div>
            <OrderFormSectionTitle>Chef Profile</OrderFormSectionTitle>
            <YSpacing height="20px" />
            <FlexContainer justifyContent="space-between" flexWrap="wrap">
              <Input
                width="31%"
                label="Custom Path"
                placeholder={'paul'}
                value={customPath}
                error={errors.customPath}
                onChange={this.updateChef('customPath')}
              />
              <Input
                width="31%"
                label="Account Name"
                placeholder={'Red Hook'}
                value={accountName}
                error={accountNameError}
                onChange={this.updateChef('accountName')}
              />
              <Input
                width="31%"
                label="Awards on chef page"
                placeholder={'James Beard Award, Iron Chef,'}
                value={accomplishments}
                maxLength="20"
                onChange={this.updateChef('accomplishments')}
              />
              <QuantityInput
                width="31%"
                label="Years as a chef"
                placeholder="10"
                value={yearsCooking}
                error={errors.chefProfile && errors.chefProfile.yearsCooking}
                onChange={this.updateChefValue('yearsCooking')}
              />
              <Input
                width="31%"
                label="Hometown / Country"
                placeholder={'Chantilly, VA'}
                value={hometown}
                error={errors.hometown}
                onChange={this.updateChef('hometown')}
              />
              <div style={{ width: '31%' }}>
                <Input
                  width="100%"
                  label="Chef Video Link"
                  placeholder=""
                  marginBottom="0"
                  value={videoLink}
                  onChange={this.updateChef('videoLink')}
                />
                <LinkText
                  onClick={() => this.setState({ showVideoPopup: true })}
                  label="How to upload video"
                  fontSize="12px"
                />
                {showVideoPopup && (
                  <Modal
                    title=""
                    hideModal={() => this.setState({ showVideoPopup: false })}
                    color="blue"
                  >
                    <p>
                      Reach out to tech@tryhungry.com to get login credentials
                      for{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        className="link-text"
                        href="https://vimeo.com/"
                      >
                        Vimeo
                      </a>
                    </p>
                    <p>
                      Watch the following video on how to upload and get your
                      chef’s video link through Vimeo.
                    </p>
                    <div
                      className="iframe-container"
                      style={{ marginTop: '20px' }}
                    >
                      <iframe
                        className="responsive-iframe"
                        src="https://player.vimeo.com/video/837663272?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </Modal>
                )}
              </div>
              <FlexContainer width="31%">
                <AuthorizedInteractable
                  roles={['master admin', 'finance', 'chef lead']}
                >
                  <CurrencyInput
                    label="Processing Fee"
                    prefix=""
                    error={
                      errors.chefProfile && errors.chefProfile.processingFee
                    }
                    value={processingFee}
                    onChange={this.updateChefValue('processingFee')}
                  />
                </AuthorizedInteractable>
                <IoInformationCircleOutline
                  fontSize="15px"
                  onMouseOver={() => this.renderProcessingFeeDetails()}
                />
              </FlexContainer>
              <div style={{ width: '62%' }}>
                <FlexContainer flexDirection="column">
                  <IoInformationCircleOutline
                    fontSize="15px"
                    onMouseOver={() => this.renderProcessingFeeReasonDetails()}
                  />
                  <AuthorizedInteractable
                    roles={['master admin', 'finance', 'chef lead']}
                  >
                    <TextArea
                      height="50px"
                      label="Adjusted Processing Fee Reason"
                      placeholder="IE: Menu price negotiated beyond standard processing fee discount"
                      maxLength="400"
                      value={processingFeeReason}
                      onChange={this.updateChef('processingFeeReason')}
                    />
                  </AuthorizedInteractable>
                </FlexContainer>
              </div>
              <FlexContainer width="100%" flexDirection="column">
                <Dropdown
                  label="Confirmation Communication Preference"
                  width="31%"
                  value={communicationPreferences}
                  onChange={this.onChangeCommunicationPreference}
                >
                  {CommunicationPreferences.map(({ value, label }) => (
                    <option
                      key={`communicationPreference${value}`}
                      value={value}
                      selected={communicationPreferences === value}
                    >
                      {label}
                    </option>
                  ))}
                </Dropdown>
              </FlexContainer>
              <FlexContainer width="31%" flexDirection="column">
                <Label>Headshot Image</Label>
                <input
                  type="file"
                  className="custom-file-input"
                  ref={(ref) => (this.chefProfileImageInput = ref)}
                  onChange={() =>
                    this.updateChefValue('image')(
                      this.chefProfileImageInput.files[0],
                    )
                  }
                />
              </FlexContainer>
              <YSpacing height="20px" />
              {id && (
                <FlexContainer width="100%" justifyContent="flex-start">
                  <FlexContainer
                    width="33%"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <Label> Partnership Logo </Label>
                    {logoUrl ? (
                      <FlexContainer flexDirection="row">
                        <a
                          className="order-link mr-2"
                          href={logoUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Open Logo
                        </a>
                        <IconButton
                          id="delete-sow"
                          onClick={() => this.setState({ logoUrl: '' })}
                        >
                          <BiTrash />
                        </IconButton>
                      </FlexContainer>
                    ) : (
                      <Label> None </Label>
                    )}
                  </FlexContainer>
                  <FlexContainer
                    width="33%"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <Label>
                      {' '}
                      {logoUrl ? 'Replace' : 'Upload '} Partnership Logo Image{' '}
                    </Label>
                    <input
                      id="uploadLogoImg"
                      type="file"
                      accept="image/*"
                      onChange={this.onChooseLogo}
                    />
                    {logoFile && (
                      <LinkText
                        label="Upload"
                        onClick={this.onSaveLogo}
                        color={colors.blue400}
                      />
                    )}
                  </FlexContainer>
                  <FlexContainer
                    width="33%"
                    justifyContent="space-between"
                    flexDirection="column"
                  >
                    <FlexContainer flexDirection="row">
                      <Label>
                        {' '}
                        {customMenuImageUrl ? 'Replace' : 'Upload '} custom menu
                        image{' '}
                      </Label>
                      <TooltipModal information="This photo will be used on all proposals when a custom menu from this Chef is created" />
                    </FlexContainer>
                    <input
                      id="uploadCustomMenuImg"
                      type="file"
                      accept="image/*"
                      onChange={this.onChooseCustomMenuImage}
                    />
                    {customMenuImageFile && (
                      <LinkText
                        label="Upload"
                        onClick={this.onSaveCustomMenuImage}
                        color={colors.blue400}
                      />
                    )}
                  </FlexContainer>
                </FlexContainer>
              )}
              <TextArea
                height="100px"
                label="Chef Bio (max length 470 letters)"
                placeholder="James Beard Award, Iron Chef, etc"
                maxLength="470"
                value={description}
                onChange={this.updateChef('description')}
              />
            </FlexContainer>
          </div>
        )}
        <DividerLine margin="20px 0" />
        <OrderFormSectionTitle>Pickup Address (Required)</OrderFormSectionTitle>
        <label className="font-bold">
          {' '}
          Address for{' '}
          <span className="text-red-600">{this.props.currentHq.name}</span>
        </label>
        <YSpacing height="20px" />
        {errors.pickupAddress && (
          <div className="error-message"> {errors.pickupAddress}</div>
        )}
        <AddressInput
          address={pickupAddress}
          showAllowTolls={true}
          onChange={(address) => this.updatePickupAddress(address)}
        />
        <DividerLine margin="20px 0" />
        {!vendorOnly && (
          <AuthorizedInteractable
            roles={['master admin', 'sales lead', 'chef lead']}
          >
            <OrderFormSectionTitle>Chef Capabilities</OrderFormSectionTitle>
            <YSpacing height="20px" />
            <FlexContainer justifyContent="space-between" flexWrap="wrap">
              <DateTextInput
                width="48%"
                marginBottom="20px"
                label="Catering Earliest Pickup Time"
                placeholder="Not Set (ex. 9:00 am)"
                date={this.pEarliestPickupTime(cateringEarliestPickupTime)}
                dateFormat="h:mm a"
                onChange={this.onChangeEarliestPickupTime(
                  'cateringEarliestPickupTime',
                )}
                onEmpty={() =>
                  this.setState(
                    {
                      cateringEarliestPickupTime: null,
                    },
                    () => delayedUpdateChef(this.state),
                  )
                }
                timeZone={locale}
              />
              <DateTextInput
                width="48%"
                marginBottom="20px"
                label="Group Order Earliest Pickup Time"
                placeholder="Not Set (ex. 9:00 am)"
                date={this.pEarliestPickupTime(groupOrderEarliestPickupTime)}
                dateFormat="h:mm a"
                onChange={this.onChangeEarliestPickupTime(
                  'groupOrderEarliestPickupTime',
                )}
                onEmpty={() =>
                  this.setState(
                    {
                      groupOrderEarliestPickupTime: null,
                    },
                    () => delayedUpdateChef(this.state),
                  )
                }
                timeZone={locale}
              />
              <QuantityInput
                width="48%"
                label="Hours of Advance Notice Needed (Optional)"
                placeholder="48"
                value={advanceHrsNotice}
                error={
                  errors.chefProfile && errors.chefProfile.advanceHrsNotice
                }
                onChange={this.updateChefValue('advanceHrsNotice')}
              />
              <QuantityInput
                width="48%"
                label="Max. Individ. Per Session (Break./Lunch/Dinner)"
                placeholder="120"
                marginBottom="20px"
                value={maxIndividual}
                error={errors.chefProfile && errors.chefProfile.maxIndividual}
                onChange={this.updateChefValue('maxIndividual')}
              />
              <QuantityInput
                width="48%"
                label="Max. Buffet Per Session (Break./Lunch/Dinner)"
                placeholder="200"
                marginBottom="20px"
                value={maxBuffet}
                error={errors.chefProfile && errors.chefProfile.maxBuffet}
                onChange={this.updateChefValue('maxBuffet')}
              />
              <QuantityInput
                width="48%"
                label="Max. Group Order Items Per Day (REQUIRED)"
                placeholder="120"
                marginBottom="20px"
                value={maxGroupOrderItems}
                error={
                  errors.chefProfile && errors.chefProfile.maxGroupOrderItems
                }
                onChange={this.updateChefValue('maxGroupOrderItems')}
              />
              <QuantityInput
                width="48%"
                label="On-Site Fee"
                placeholder="100"
                marginBottom="20px"
                value={onSiteFee}
                error={errors.chefProfile && errors.chefProfile.onSiteFee}
                onChange={this.updateChefValue('onSiteFee')}
              />
              <QuantityInput
                width="48%"
                label="Minimum Catering Order Payout"
                placeholder="500"
                marginBottom="20px"
                value={minCateringPayout}
                error={
                  errors.chefProfile && errors.chefProfile.minCateringPayout
                }
                onChange={this.updateChefValue('minCateringPayout')}
              />
            </FlexContainer>
          </AuthorizedInteractable>
        )}
        <YSpacing height="20px" />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <TechHelpForm margin="0 30px 0 0" />
          {id && (
            <AuthorizedInteractable roles={editRoles}>
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Delete"
              />
            </AuthorizedInteractable>
          )}
          <XSpacing width="20px" />
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          <XSpacing width="20px" />
          <AuthorizedInteractable roles={editRoles}>
            <Button
              onClick={this.onSubmit}
              disabled={isLoading}
              label={isLoading ? 'Saving...' : 'Save'}
            />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

EditChefModal.propTypes = {
  locale: PropTypes.string,
  chef: PropTypes.object,
  currentHq: PropTypes.object,
  errors: PropTypes.object,
  settings: PropTypes.object,
  show: PropTypes.bool,
  isLoading: PropTypes.bool,

  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateChef: PropTypes.func,
  deleteChef: PropTypes.func,
  loadChefsSettings: PropTypes.func,
  saveChef: PropTypes.func,
  loadChefs: PropTypes.func,
  selectChef: PropTypes.func,
  uploadChefLogoImage: PropTypes.func,
  uploadChefCustomMenuImage: PropTypes.func,
  checkPdfImage: PropTypes.func,
  displayFailureMessage: PropTypes.func,
}

export default EditChefModal
