import React, { Component } from 'react'
import analytics from '@analytics'
import PropTypes from 'prop-types'
import { Route } from 'react-router'

import { SurveyPage } from '@components/survey'
import { SupplyDetail } from '@components/supply'

import { AccountPage } from '@containers/account'
import { ReportsPage } from '@components/reports'
import { ConsumptionReportsPage } from '@containers/consumption'
import { PlaygroundPage } from '@containers/cateringConcierge'
import { ChefPage } from '@containers/chef'
import { ChefNetworkPage } from '@containers/chefNetwork'
import { CafeMaster } from '@containers/cafes'
import { FeedbackPage } from '@components/feedback'
import { MenuItemsPage } from '@components/menuItemsSearch'
import { DashboardPage } from '@containers/dashboard'
import { MarketsPage } from '@containers/market'
import { NavBar } from '@containers/common'
import { Homepage } from '@components/common/homepage'
import { OrderPage } from '@containers/order'
import { ProposalPage } from '@containers/proposal'
import { ProposalMasterDetail } from '@components/common'
import { SubscriptionWrapperPage } from '@components/subscription'
import { UserPage } from '@containers/user'
import { UserSearchPage } from '@containers/userSearch'
import { PopUpMaster } from '@containers/popUps'
import { InvoicesPage } from '@containers/invoice'
import { VXMaster } from '@containers/virtualExperiences'
import { GroupOrdersMaster } from '@containers/groupOrders'
import { GroupOrderSearchPage } from '@containers/groupOrderSearch'
import { NotificationsPage } from '@containers/notifications'
import { HubspotPage } from '@containers/hubspot'

import {
  NatsOrderPage,
  RangersOrderPage,
  MLBManageOrdersPage,
} from '@containers/mlbPartners'

class Home extends Component {
  componentDidMount() {
    const { initBraintree, redirect, identifyUser } = this.props

    setTimeout(() => {
      if (!this.props.currentUser) {
        redirect()

        return
      }

      this.props.currentUser && identifyUser(this.props.currentUser.email)
      this.props.currentUser && analytics.identify(this.props.currentUser.email)
    }, 1000)

    initBraintree()
  }

  render() {
    const { currentUser, match } = this.props
    const { url } = match
    if (!currentUser) {
      return null
    }

    return (
      <div>
        <NavBar />
        <div className="body-container">
          <div className="children-container">
            <Route exact path={`${url}`} component={Homepage} />
            <Route path={`${url}chefs`} component={ChefPage} />
            <Route path={`${url}chef-network`} component={ChefNetworkPage} />
            <Route path={`${url}menuitems`} component={MenuItemsPage} />
            <Route path={`${url}sales`} component={OrderPage} />
            <Route path={`${url}proposals`} component={ProposalPage} />
            <Route
              path={`${url}proposals-dashboard`}
              component={ProposalMasterDetail}
            />
            <Route path={`${url}dashboard`} component={DashboardPage} />
            <Route path={`${url}users`} component={UserPage} />
            <Route path={`${url}feedback`} component={FeedbackPage} />
            <Route
              path={`${url}consumption`}
              component={ConsumptionReportsPage}
            />
            <Route path={`${url}search-users`} component={UserSearchPage} />
            <Route path={`${url}clients`} component={AccountPage} />
            <Route path={`${url}invoices`} component={InvoicesPage} />
            <Route path={`${url}reports`} component={ReportsPage} />
            <Route path={`${url}surveys`} component={SurveyPage} />
            <Route path={`${url}markets-manager`} component={MarketsPage} />
            <Route path={`${url}supplies`} component={SupplyDetail} />
            <Route
              path={`${url}subscriptions`}
              component={SubscriptionWrapperPage}
            />
            <Route path={`${url}nationals`} component={NatsOrderPage} />
            <Route path={`${url}rangers`} component={RangersOrderPage} />
            <Route path={`${url}pop-ups`} component={PopUpMaster} />
            <Route
              path={`${url}group-order-search`}
              component={GroupOrderSearchPage}
            />
            <Route
              path={`${url}manage-mlb-orders`}
              component={MLBManageOrdersPage}
            />
            <Route path={`${url}virtual-xp`} component={VXMaster} />
            <Route path={`${url}group-orders`} component={GroupOrdersMaster} />
            <Route path={`${url}cafes`} component={CafeMaster} />
            <Route path={`${url}notifications`} component={NotificationsPage} />
            <Route path={`${url}hubspot`} component={HubspotPage} />
            <Route
              path={`${url}catering-concierge`}
              component={PlaygroundPage}
            />
          </div>
        </div>
      </div>
    )
  }
}

Home.propTypes = {
  currentUser: PropTypes.object,
  match: PropTypes.object,

  initBraintree: PropTypes.func,
  redirect: PropTypes.func,
  identifyUser: PropTypes.func,
}

export default Home
